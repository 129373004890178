<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.footerBar{
    display: flex;
    align-items: flex-end;
.bar {
    display: flex;
    justify-content: center;
    align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgb(27, 27, 27);
  bottom: 0;
  p{
      text-decoration: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      color: white;
  }
}}
</style>
<template>
  <div class="footerBar">
    <div class="bar">
    <p>©&nbsp;2021 Liquidzed</p></div>
  </div>
</template>
<script>
export default {
  name: "footerBar",
};
</script>
