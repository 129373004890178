<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.rightBar {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .cart:hover > .cartBack {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .cart {
    z-index: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;

    width: 50px;
    height: 50px;
    .cartBack {
      position: absolute;
      z-index: 0;
      width: 50px;
      height: 50px;
      border-radius: 12.5px;
      background-color: rgba(86, 84, 203, 0.7);
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
    }

    border-radius: 12.5px;
    .numOrders {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      background-color: rgb(58, 78, 255);
      border-radius: 35%;
      width: 15px;
      height: 15px;
      margin-left: -20px;
      margin-top: 20px;

      p {
        color: rgb(255, 255, 255);
        font-size: 10px;
        font-weight: 600;
      }
    }
    img {
      z-index: 0;
      height: 25px;
    }
  }
}
.hamburger {
  display: none;
}

.topBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  width: 100%;

  nav {
    height: 130px;
    width: 100%;
    margin-left: 50px;
    margin-right: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  a,
  .wrap {
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    text-decoration: none;
    color: black;
  }
  .leftBar {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
  }
  .middle {
    display: flex;
    align-items: center;
    width: 40%;
  }
  
  @media only screen and(max-width: 1300px) {
    .leftBar {
      gap: 120px;
    }
    .middle{
      width: 80%;
    }
  }
  @media only screen and(max-width: 1000px) {
    .mainContent .page{
      display: flex;
    justify-content: center;
    }
    nav {
      margin-right: 20px;
    }
    .middle {
      width: 60%;
      
    }
    .rightBar{
      width: 20%;
    }
    .hamburger {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 22px;
      margin-top: 1px;
    }
    .hamburger div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 25px;
      background-color: black;
      height: 3px;
    }

    .mobile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .mobile svg {
      height: 25px;
    }
    nav {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    .leftBar {
      width: 20%;
      display: none;
    }
  }
  @media only screen and(max-width: 600px){
    nav {
      margin-top: 5px;
      flex-direction: column;
      height: 110px;
      justify-content: space-evenly;
      margin-left: 0;
      margin-right: 0;
      gap: 0px;
    }
    .rightBar{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-right: 35px;
    gap: 10px;
    }
    .middle{
      order: 2;
      width: 100%;
    }
    .border{
      width: 100%;
      border-radius: 0;
      border: 0;
      background-color: rgba(240, 240, 240, 1);
    }
  }
}
</style>

<template>
  <div class="topBar">
    <nav>
     <div class="leftBar">
         <!--<router-link to="/"><div class="wrap">Domů</div></router-link>
        <a href="/#wall"><div class="wrap">Zeď</div></a>

        <a href="/#contacts"><div class="wrap">Kontakty</div></a>-->
      </div>
      <div class="middle">
        <searchBar />
      </div>
      <div class="rightBar">
        <router-link to="/cart">
          <div class="cart">
            <div class="cartBack"></div>
            <div class="numOrders">
              <p>{{ cartItemCount }}</p>
            </div>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/1/1a/Font_Awesome_5_solid_shopping-bag.svg"
              alt=""
            />
          </div>
        </router-link>
        <div class="hamburger">
          <div class="burgerButtonTop"></div>
          <div class="burgerButtonMid"></div>
          <div class="burgerButtonBot"></div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import searchBar from './searchBar.vue'
export default {
  name: "topBar",
  components: {
    searchBar,
  },
  computed: {
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
  },
  mounted() {
    console.log(this.$store.getters.cartItemCount);
  },
};
</script>

