<style lang="scss">
#app {
  overflow-anchor: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background-color: #f5f5f5;
  overflow: hidden;
  touch-action: manipulation;
}
* {
  margin: 0;
  padding: 0;
  overflow: 0;
}

html,
body {
  background-color: #f5f5f5;
  touch-action: manipulation;
  scroll-behavior: smooth;
}
input,
textarea,
button,
select,
a{
  -webkit-tap-highlight-color: transparent;
}

main{
  position: absolute;
  z-index: 100;
}
.contact {
  width: 100%;
  height: 81.6vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.login {
  display: none;
}
.fadeOut-leave-active {
  transition: opacity 0.2s;
}
.fadeOut-leave-to {
  opacity: 0;
}
</style>


<template>
  <div id="app" v-if="products != undefined || products != null">
    <transition name="fadeOut">
    <loader v-if="itemRoute && productLoading == false"/>
    </transition>
    <topBar/>
    <transition name="fade">
    <searchItemList v-show="searchItemList !== false"/>
    </transition>
    <!--<transition name="fade" mode="out-in">-->
      <router-view/>
    <!--</transition>-->
    
  </div>
</template>
<script>
import topBar from "./components/nav.vue"
import searchItemList from './components/items/searchItemList.vue'
import loader from './components/loader/loader.vue'

export default {
  name: "app",
  mounted() {
    this.$store.dispatch("getProducts");
    console.log(searchItemList)
  },
  created() {
    document.title = "LiquidZed, the best place to find and buy liquid items"
  },

  components: {
    topBar,
    searchItemList,
    loader
  
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
    searchItemList() {
      return this.$store.state.searchItemList;
    },

    productLoading(){
      return this.$store.state.productLoading;
    },
    itemRoute(){
      return this.$route.name === 'item'
    }
  }
};
</script>

