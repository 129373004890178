<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.contactBox {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #202020;
  .container {
    padding: 75px;
    width: 100%;
    color: white;

    .links {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 30px;

      .title {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 25%;
        flex-basis: 25%;
        h1 {
          font-weight: 500;
        }
        a {
          color: rgb(151, 151, 151);
          text-decoration: none;
        }
      }
      .newsletters {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 25%;
        flex-basis: 25%;
        h2 {
          font-weight: 400;
        }
        form {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          input[type="email"] {
            max-width: 50%;
          }
          input[type="submit"] {
            max-width: 100px;
          }
        }
      }
      .socials {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 25%;
        flex-basis: 25%;
        h2 {
          font-weight: 400;
        }
        svg {
          color: rgb(151, 151, 151);
          height: 30px;
        }
        a:hover .svg-inline--fa.fa-facebook-square.fa-w-14 {
          color: #3b5998;
          transition: all 0.2s;
        }
        a:hover .svg-inline--fa.fa-instagram.fa-w-14 {
          color: #d6249f;
          transition: all 0.2s;
        }
        a:hover .svg-inline--fa.fa-youtube.fa-w-18 {
          color: #ff1e1e;
          transition: all 0.2s;
        }
      }
      .contacts {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 25%;
        flex-basis: 25%;
        h2 {
          font-weight: 400;
        }
        a {
          color: rgb(151, 151, 151);
          text-decoration: none;
        }
      }
    }
  }
  .sign {
    display: flex;
    svg {
      height: 10px;
    }
    a {
      text-decoration: none;
      color: white;
    }
  }
}
@media only screen and (max-width: 750px) {
  .contactBox {
    .container {
      .links {
        .newsletters,
        .title,
        .contacts,
        .socials {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
  }
}
@media only screen and(max-width: 300px) {
  .contactBox {
    .container {
      .links {
        .newsletters,
        .title,
        .contacts,
        .socials {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
<template>
  <div class="contactBox" id="contacts">
    <div class="container">
      <div class="links">
        <div class="title">
          <h1>Liquidzed</h1>
          <a href="/#">Domů</a>
          <a href="/#wall">Zeď</a>
          <a href="/#contacts">Kontakty</a>
        </div>
        <div class="newsletters">
          <h2>Newsletters</h2>
          <form action="">
            <input type="email" name="" id="" />
            <input type="submit" value="Odebírat!" />
          </form>
        </div>
        <div class="socials">
          <h2>Socials</h2>
          <a href="">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook-square"
              class="svg-inline--fa fa-facebook-square fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
              ></path>
            </svg>
          </a>
          <a href="">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="instagram"
              class="svg-inline--fa fa-instagram fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              ></path>
            </svg>
          </a>
          <a href="">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="youtube"
              class="svg-inline--fa fa-youtube fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              ></path>
            </svg>
          </a>
        </div>
        <div class="contacts">
          <h2>Contacts</h2>
          <a href="mailto: petr.ziegner@seznam.cz">info@liquidzed.cz</a>
          <a href="tel: +420 775 661 883">+420 775 661 883</a>
        </div>
      </div>
    </div>
    <!-- <div class="sign">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        /></svg
      ><a href="https://hejduk.tech">&nbsp;michalhejduk</a>
    </div>-->
  </div>
</template>
<script>
export default {
  name: "footerLinks",
  props: {
    username: String,
  },
  methods: {
    home() {
      console.log("V komponente");
      this.$emit("home");
    },
    wall() {
      console.log("V komponente");
      this.$emit("wall");
    },
    contacts() {
      console.log("V komponente");
      this.$emit("contacts");
    },
  },
};
</script>
