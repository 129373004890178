<style lang="scss" scoped>
.item {
  width: 100%;
}
</style>
<template>
  <div class="item">
      <div class="page">
        <div v-if="productLoading" class="wrap">
          {{ product.name }}
          <br />
          {{ product._id }}
          <br />
          {{ product.price }}
          <br />
          <button @click="addToCart()">Add to cart</button>
          <itemPreview />
        </div>
      </div>
  </div>
</template>
<script>
import itemPreview from "@/components/items/itemPreview.vue";
export default {
  name: "item",
  components: {
    itemPreview,
  },
  methods: {
    addToCart() {
      this.$store.dispatch(
        "addItemToCart",
        {
          product: this.product,
          quantity: 1,
        },
        this.$router.push("/")
      );
    },
  },
  computed: {
    product() {
      return this.$store.state.product;
    },
    productLoading() {
      return this.$store.state.productLoading;
    },
  },
  mounted() {
    console.log(
      this.$store.state.products.some(
        (product) => product.title === this.$route.params.id
      )
    );
    this.$store.dispatch("getProduct", this.$route.params.id);
  },
};
</script>
