<<<<<<< HEAD
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.box:hover .container .img img {
  transform: scale(1.1);
}
.box {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 18px 18px 20px #d1d9e6, -18px -18px 20px rgba(255, 255, 255, 0.8);
  z-index: 0;
  .container {
    padding: 20px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    flex-direction: column;

    img {
      border-radius: 12.5px;
      width: 100%;
      height: 100%;

      transition: 0.3s;
    }
  }
  .insideText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12.5px;
    padding: 5px;
    gap: 10px;
    .leftSide{
    display: flex;
    flex-direction: column;
    gap: 10px;
    }
  }
}
.header p{
  color: black;
  font-weight: 500;
  font-size: 18px;
}
.price p{
  color: black;
  z-index: 100;
  font-size: 16px;
}

</style>

<template>
<router-link :to="{name: 'item', params: {id: product.title}}" style="text-decoration: none">
  <div class="box">
    <div class="container">
      <div class="img">
        <!--"`/img/${product.image}`"-->
        <img
          :src="`d/img/${product.image}`"
          alt=""
        />
      </div>
      <div class="insideText">
        <div class="leftSide">
          <div class="header"><p>
          {{ product.name}}
          </p></div>
          <div class="price">
            <p>{{ product.price}},-</p>
          </div>
        </div>
        <div class="rightSide"></div>
      </div>
    </div>
  </div>
  </router-link>
</template>
<script>
export default {
  name: "itemBox",
  props: ["product"]
};
</script>
=======
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.box:hover .container .img img {
  transform: scale(1.1);
}
.box {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 18px 18px 20px #d1d9e6, -18px -18px 20px rgba(255, 255, 255, 0.8);
  z-index: 0;
  .container {
    padding: 20px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    flex-direction: column;

    img {
      border-radius: 12.5px;
      width: 100%;
      height: 100%;

      transition: 0.3s;
    }
  }
  .insideText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12.5px;
    padding: 5px;
    gap: 10px;
    .leftSide{
    display: flex;
    flex-direction: column;
    gap: 10px;
    }
  }
}
.header p{
  color: black;
  font-weight: 500;
  font-size: 18px;
}
.price p{
  color: black;
  z-index: 100;
  font-size: 16px;
}

</style>

<template>
<router-link :to="{name: 'item', params: {id: product.title}}" style="text-decoration: none">
  <div class="box">
    <div class="container">
      <div class="img">
        <!--"`/img/${product.image}`"-->
        <img
          :src="`d/img/${product.image}`"
          alt=""
        />
      </div>
      <div class="insideText">
        <div class="leftSide">
          <div class="header"><p>
          {{ product.name}}
          </p></div>
          <div class="price">
            <p>{{ product.price}},-</p>
          </div>
        </div>
        <div class="rightSide"></div>
      </div>
    </div>
  </div>
  </router-link>
</template>
<script>
export default {
  name: "itemBox",
  props: ["product"]
};
</script>
>>>>>>> f9e145dadc4bbec660bf2e91decdd20ca22d3a05
