<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  overflow: 0;
}
html,
body {
  position: absolute;
  top: 0;
}

</style>
<template>
  <div class="main">
    <slideBox />
    <itemList/>
    <footerLinks />
    <footerBar />
  </div>
</template>

<script>
// @ is an alias to /src
import footerBar from "@/components/footer/footerBar.vue";
import slideBox from "@/components/slideBox.vue";
import footerLinks from "@/components/footer/footerLinks.vue";
import ItemList from '../components/items/itemList.vue';

export default {
  name: "Home",
  components: {
    footerLinks,
    footerBar,
    slideBox,
    ItemList
  },
  mounted() {
        document.title="LiquidZed, the best place to find and buy liquid items";

  },
};
</script>

