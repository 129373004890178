<style scoped lang="scss">
.box{
    margin: 20px;
    margin-left:40px;
    margin-right:40px;
display: flex;
justify-content: center;

}
.slideBox{

width: 1000px;
aspect-ratio: 2.3/1;
background-color: #403c54;
border-radius: 30px;
}
@media only screen and (max-width: 545px){
    .box{
        margin-left: 15px;
        margin-right: 15px;
    }
}
@media only screen and (max-width: 450px){
    .box{
        margin-left: 10px;
        margin-right: 10px;
    }
.slideBox{
    aspect-ratio:unset;
height: 200px;
width: 100%;
}

}
</style>
<template>
    <div class="box">
    <div class="slideBox"></div>
    </div>
</template>
<script>
export default {
    name: 'slideBox'
}
</script>
