<style lang="scss" scoped>
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #403c54;
  z-index: 1000000000;
  .loadingCircle {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #181818; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
<template>
  <div class="loader">
    <div class="loadingCircle"></div>
  </div>
</template>

<script>
export default {};
</script>

