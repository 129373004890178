import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersist from 'vuex-persist'
import router from '../router/index.js'

Vue.use(Vuex)
const URL = 'https://liquidzed.herokuapp.com'

const vuexLocalStorage = new VuexPersist({
   storage: window.localStorage,
   reducer: state =>({
     cart: state.cart
   })
 })
export default new Vuex.Store({

  state: {
    products: undefined,
    product: undefined,
    cart: [],
    productLoading: false,
    searchItemList: false

  },
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    ADD_TO_CART(state, { product, quantity }) {
      let productInCart = state.cart.find(item => {
        return item.product.title === product.title
      },
      );
      
      if (productInCart) {
        productInCart.quantity += quantity;
        return;
      }
      
      state.cart.push({
        product,
        quantity
      })
      
    },
    PRODUCTS_LOADING(state, productLoading) {
      state.productLoading = productLoading;
    },
    REMOVE_ITEM_FROM_CART(state, product) {
      console.log(product);
      let index = state.cart.indexOf(product);
      state.cart.splice(index, 1);
    },
    REMOVE_ONE_ITEM_FROM_CART(state, product) {
      let index = state.cart.indexOf(product)
      console.log(index)
      state.cart[index].quantity -= 1;
      if(state.cart[index].quantity <= 0){
        state.cart.splice(index, 1);
      }
    },
    ADD_ONE_ITEM_TO_CART(state, product) {
      let index = state.cart.indexOf(product)
      console.log(index)
      state.cart[index].quantity += 1;
      if(state.cart[index].quantity <= 0){
        state.cart.splice(index, 1);
      }
    },
    SEARCH_ITEM_LIST(state, searchItemList) {
      state.searchItemList = searchItemList;
    }
  },
  actions: {
    // get all products
    async getProducts({ commit }) {
      
      await axios.get(`${URL}/p`)
        .then(response => {
          commit('SET_PRODUCTS', response.data)
        })
    },
    // get single product
    async getProduct({ commit }, id) {
      commit('PRODUCTS_LOADING', false);
      await axios.get(`${URL}/p?p=${id}`)
        .then(response => {
          commit('SET_PRODUCT', response.data)
          if(response.data === '' || response.data.length === 0){
            commit('PRODUCTS_LOADING', false);
            router.push({ name: 'pageNotFound' });
          }
          else{
            document.title = response.data.name
            setTimeout(() => {commit('PRODUCTS_LOADING', true)}, 100);
          }
        }, error => {
          console.log(error);
        })
    },
    addItemToCart({ commit }, { product, quantity }) {
      commit('ADD_TO_CART', { product, quantity });
    },
    removeItemFromCart({ commit },  product ) {
      commit('REMOVE_ITEM_FROM_CART',  product );
    },
    removeOneItemFromCart({ commit },  product ) {
      commit('REMOVE_ONE_ITEM_FROM_CART',  product );
    },
    addOneItemToCart({ commit },  product ) {
      commit('ADD_ONE_ITEM_TO_CART',  product );
    },
    searchBarFocused({ commit }) {
      commit('SEARCH_ITEM_LIST', true);
    },
    searchBarBlured({ commit }) {
      commit('SEARCH_ITEM_LIST', false);
    }
  },
  getters: {
    // celkovy pocet produktu
    cartItemCount(state) {
      let itemCount = 0;
      state.cart.forEach(item => {
        itemCount += item.quantity
      })
      return itemCount;
    },
    // celkova cena
    cartTotalPrice(state) {
      let total = 0;
      state.cart.forEach(item => {
        total += item.product.price * item.quantity;
      })
      return total;
    }
  },
  plugins: [vuexLocalStorage.plugin]
})
