<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.itemContainer {
  overflow-anchor: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 25px;
}
.mainContent {
  box-sizing: border-box;
  padding: 20px;
  padding-left: 200px;
  padding-right: 200px;
  overflow-anchor: none;
  display: flex;
  flex-direction: column;

  gap: 50px;
  font-family: "Roboto", sans-serif;

  .page {
    width: 100%;
    display: flex;

    .content {
      margin: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 25px;
      .filter {
        border-radius: 25px;
        width: 300px;
        height: 1000px;
        z-index: 10;
        background-color: #403c54;
      }
      .items {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 35px;
        text-align: left;
        .news {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 15px;
          header {
            z-index: 1000;
            width: 100%;
            text-align: left;
          }
        }
        .ecig {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 15px;
          text-align: left;
          header {
            z-index: 1000;
            width: 100%;
            text-align: left;
          }
        }
        .liqs {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 15px;
          text-align: left;
          header {
            z-index: 1000;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
}
@media only screen and(max-width: 1400px) {
  .itemContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and(max-width: 1240px){
  .mainContent{
    width: 100%;
    padding: 25px;
  }
}
@media only screen and(max-width: 1150px) {
  .itemContainer {
    grid-template-columns: repeat(4, 1fr);
  }
  .content {
    gap: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .itemContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .filter {
    display: none;
  }
  .mainContent {
    align-items: center;
  }
  .mainContent .page {
    width: 90%;
    justify-content: center;
  }
  .content {
    justify-content: center;
  }
}
@media only screen and(max-width: 600px){
  .itemContainer{
    grid-template-columns: repeat(2, 1fr);
  }
  .mainContent .page {
    width: 100%;
    justify-content: center;
  }
}
</style>

<template>
  <div class="mainContent">
    <div class="page" id="wall">
      <div class="content">
        <div class="filter"></div>
        <div class="items">
            <div class="itemContainer">
              <template v-for="product in products">
                <itemBox
                  :key="product.title"
                  :product="product"
                  v-if="product.visible"
                />
              </template>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import itemBox from "./itemBox.vue";

export default {
  name: "mainContent",
  components: {
    itemBox,
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
  },
  mounted() {
    this.$store.dispatch("getProducts");
  },
};
</script>

