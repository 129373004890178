<style lang="scss" scoped>
.border {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 2px solid rgb(63, 63, 63);
  height: 40px;
}
input {
  width: 100%;
  outline: 0;
  height: 40px;
  border: 0;
  background: none;
  font-size: 16px;
  padding-left: 5px;
}
.searchBox {
  border-left: 2px solid rgb(63, 63, 63);
  box-sizing: border-box;
  width: 45px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  svg {
    width: 20px;
  }
}
@media only screen and(max-width: 600px) {
  .searchBox {
    border-left: 0;
    justify-content: flex-start;
  }
  input {
    padding-left: 15px;
  }
}
</style>
<template>
  <div class="border">
    <input
      type="text"
      v-model="searching"
      @focus="searchBarFocused()"
      @blur="searchBarBluerd()"
    />
    <div class="searchBox">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="search"
        class="svg-inline--fa fa-search fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
        ></path>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "searchBar",
  data() {
    return {
      searching: "",
    };
  },
  methods: {
    searchBarFocused() {
      this.$store.dispatch("searchBarFocused");
    },
    searchBarBluerd() {
      this.$store.dispatch("searchBarBlured");
    },
  },
};
</script>
