<template>
  <div class="cart">
    <div v-for="item in cart" :key="item.product.title">
      {{ item.product.name }}
      {{ item.quantity }}
      {{ item.product.price }}
      {{ item.product.price * item.quantity }}
      <button @click="addOneItemToCart(item)">+</button>
      <button @click="removeOneItemFromCart(item)">-</button>
      <button @click="removeItemFromCart(item)">remove from cart</button>
      <hr />
    </div>
    <div class="no-item-in-cart" v-if="cart == undefined || cart == null || cart == [] || cart == 0 || cart == {} || cart == []">
    <p>Tvůj košík je prázdný, naše produkty najdeš</p><router-link to="/">zde</router-link><p>!</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "cart",
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    removeItemFromCart(item) {
      this.$store.dispatch("removeItemFromCart", item);
    },
    removeOneItemFromCart(item) {
      this.$store.dispatch("removeOneItemFromCart", item);
    },
    addOneItemToCart(item) {
      this.$store.dispatch("addOneItemToCart", item);
    },
  },
};
</script>
