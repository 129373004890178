import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import cart from '@/views/cart.vue'
import item from '@/views/item.vue'
import pageNotFound from '@/components/pageNotFound.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cart',
    name: 'cart',
    component: cart
  },
  {
    path: '/:id',
    name: 'item',
    component: item
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: pageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
